.page-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
}

.page-footer-credits {
  font-weight: 100;
  color: #ffc048;
  text-align: center;
  border: 2px solid #141a1f;
  border-radius: 5px;
  padding: 10px 10px;
  display: inline-block;
  font-size: 0.8em;
}

.page-footer-bold {
  font-weight: 800;
}

.page-footer-logout-btn {
  cursor: pointer;
}

.privacy-policy-link {
  color: #485460;
  text-decoration: underline;
  margin-right: 5px;
}

.privacy-policy-link:hover {
  color: #575fcf;
}

.author-link {
  margin-right: 5px;
}

.author-link:hover {
  text-decoration: underline;
}

.buy-me-a-coffee-link {
  background-color: #141a1f;
  font-weight: bold;
  color: #4bcffa;
  border-radius: 5px;
  padding: 5px 10px;
  transition: 300ms ease-in-out all;
  cursor: pointer;
}

.buy-me-a-coffee-link:hover {
  color: #575fcf;
}

.buy-me-a-coffee-container {
  display: inline-block;
}

.container-lg {
  display: none;
}

.container-sm {
  display: none;
  margin-bottom: 20px;
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 767px) {
  .container-sm {
    display: block;
  }

  .container-lg {
    display: none;
  }
}

@media only screen and (min-width: 768px) {
  .container-sm {
    display: block;
  }

  .container-lg {
    display: none;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .container-sm {
    display: none;
  }

  .container-lg {
    display: inline-block;
  }
}

