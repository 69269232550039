.app-logo {
  text-align: center;
  font-weight: 100;
  font-size: 4em;
  font-family: 'Pacifico', sans-serif;
  margin: 0.50em;
}

.bee-icon {
  height: 70px;
  position: relative;
  top: 10px;
  margin: 0px 5px 0px 13px;
  transition: 200ms;
}

.bee-icon:hover {
  color: white;
  transform: rotate(0.05turn);
  cursor: pointer;
}

.logo-text {
/*  color: #ffc048;*/
/*  background: #1e272e;*/
/*  text-shadow: 0px 6px 0px #0d1215;*/
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .app-logo {
    font-size: 3em;
    margin: 0.1em;
  }

  .bee-icon {
    height: 60px;
  }
}
