*:focus {
  outline: none;
}

.sound-button-area {
  grid-area: sound-button-area;
}

.sound-buttons {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}

.sound-button-extras {
  display: flex;
  padding: 20px 40px;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}

.sound-group {
  border-radius: 5px;
  padding-bottom: 10px;
  z-index: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 170px;
  align-content: flex-start;
}

.sound-group-category {
  flex: 0 0 100%;
}

.sound-group-category div {
  font-size: 1em;
  font-family: 'Seven Segment', sans-serif;
  background-color: #141a1f;
  display: inline-block;
  padding: 2.5px 5px 2.5px 5px;
  margin-left: 5px;
  border-radius: 5px;
}

.category-centered {
  text-align: center;
}

.settings-area {
  grid-area: settings-area;
  padding: 0px 10px;
}

.settings {
  min-height: 100px;
  display: block;
  position: relative;
  padding: 20px;
  border-radius: 5px;
  background-color: #141a1f;
  box-shadow: inset 0 1px 0 #3a4854, 0 5px 0 #0e1215;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 20px;
}

.settings-section {
  flex: 0 0 100%;
  padding: 0px;
}

.about-area {
  grid-area: about-area;
  min-height: 100px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  justify-content: center;
}

.banner-ad-area {
  border: 2px solid #485460;
  min-height: 100px;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.page-home-container {
  display: grid;
  grid-auto-columns: minmax(0, 1fr);
  grid-auto-flow: column;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .page-home-container {
    grid-template-areas:
      'settings-area'
      'sound-button-area'
      'about-area';
  }

  .settings-section {
    flex: 0 0 100%;
    padding: 0px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .page-home-container {
    grid-template-areas:
      'settings-area'
      'sound-button-area'
      'about-area';
  }

  .settings-section {
    flex: 0 0 48%;
    padding: 0px 1%;
  }

  .volume-control {
    height: 61px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .page-home-container {
    grid-template-areas:
      'settings-area'
      'sound-button-area'
      'about-area';
  }

  .settings-section {
    flex: 0 0 48%;
    padding: 0px 1%;
  }

  .volume-control {
    height: 61px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .page-home-container {
    grid-template-areas:
      'sound-button-area sound-button-area settings-area'
      'sound-button-area sound-button-area settings-area'
      'about-area about-area about-area';
  }

  .settings-section {
    flex: 0 0 100%;
    padding: 0px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .page-home-container {
    grid-template-areas:
      'sound-button-area sound-button-area settings-area'
      'sound-button-area sound-button-area settings-area'
      'about-area        about-area        settings-area';
  }
}

.page-home-join-room-input {
  background-color: #ffc048;
  border-radius: 5px;
  border: 1px solid #12171b;
  color: #12171b;
  padding: 14px;
  margin-bottom: 14px;
  font-size: 1em;
  width: 100%;
}

.page-home-join-room-input::placeholder {
  color: #485460;
}

.page-home-tutorial-header {
  font-size: 1.25em;
  margin-bottom: 10px;
  text-align: center;
}

.page-home-lobby-header {
  margin-bottom: 10px;
  text-align: center;
}

.page-home-tutorial-header {
  color: #d2dae2;
  font-weight: bold;
}

.page-home-join-room-btn {
  border-radius: 5px;
  border: 1px solid #05c46b;
  text-align: center;
  cursor: pointer;
  background-color: #05c46b;
  color: #1e272e;
  width: 100%;
  padding: 10px;
  font-size: 1em;
  transition: 300ms ease-in-out all;
}

.page-home-join-room-btn-disabled {
  border-radius: 5px;
  border: 1px solid #485460;
  text-align: center;
  cursor: not-allowed;
  color: #485460;
  width: 100%;
  padding: 10px;
  font-size: 1em;
}

.page-home-join-room-btn:hover { opacity: 0.5; }
.page-home-join-room-btn:active { opacity: 0.25; }

.page-home-join-room-btn-disabled {}

.invalid-room-message {
  color: #eb4d4b;
  text-align: center;
}

.faded-display {
  opacity: 0.5;
}

.settings-header {
  font-size: 20px;
  color: #ffc048;
  padding: 5px 0px;
}

.page-home-lobby-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
}

.page-home-col {
  flex: 0 0 20%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 10px 20px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .page-home-col {
    flex: 0 0 100%;
  }

  .page-home-lobby-container {
    flex-direction: column-reverse;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .page-home-col {
    flex: 0 0 40%;
  }

  .page-home-col:last-child {
    flex: 0 0 80%;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .page-home-col {
    flex: 0 0 40%;
  }

  .page-home-col:last-child {
    flex: 0 0 80%;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .page-home-col, .page-home-col:last-child {
    flex: 0 0 40%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .page-home-col, .page-home-col:last-child {
    flex: 0 0 25%;
  }
}

.page-home-how-to-list {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

.page-home-how-to-item {
  text-align: center;
}

.page-home-lobby-join-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 30px;
  background-color: #141a1f;
  border-radius: 5px;
}


.tutorial {
  height: 100px;
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
}

.tutorial-1-box {
  width: 200px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #141a1f;
  border-radius: 5px;
  align-content: center;
}

.tutorial-1-shape-1 {
  height: 2.5px;
  width: 85%;
  background-color: #ffc048;
  margin: 0px 10px;
}

.tutorial-1-shape-2 {
  height: 20px;
  border-radius: 5px;
  width: 100%;
  background-color: #ffc048;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorial-1-shape-2-a {
  height: 5px;
  width: 94%;
  background-color: #141a1f;
}

.tutorial-1-shape-3 {
  height: 20px;
  border-radius: 5px;
  width: 100%;
  background-color: #05c46b;
  margin: 0px 10px;
  color: #1e272e;
}

.tutorial-2-box {
  width: 200px;
  height: 100px;
  min-height: 100px;
  position: relative;
  border-radius: 5px;
  background-color: #141a1f;
  box-shadow: inset 0 1px 0 #3a4854, 0 5px 0 #0e1215;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: 20px;
  align-content: center;
}

.tutorial-2-shape-1 {
  height: 2.5px;
  width: 85%;
  background-color: #ffc048;
  margin: 0px 10px;
}

.tutorial-2-shape-2 {
  height: 20px;
  border-radius: 5px;
  width: 100%;
  background-color: #ffc048;
  margin: 10px 10px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  color: black;
  font-family: 'Seven Segment', sans-serif;
}

.page-home-join-desc {
  margin-top: 20px;
  color: #d2dae2;
  font-size: 0.85em;
  text-align: center;
}

.tutorial-3-box {
  width: 200px;
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: #141a1f;
  border-radius: 5px;
  align-content: center;
  padding-bottom: 10px;
}

.tutorial-3-shape-1 {
}

.tutorial-3-shape-1 div {
  color: white;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  position: relative;
  width: 35px;
  height: 35px;
  margin: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.explanation {
  height: 100px;
  width: 100%;
  margin: 10px 0px;
  display: flex;
  justify-content: center;
}

.explanation-1 {
  margin-top: 0px;
}

.explanation-1-box {
  width: 200px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 2px solid #485460;
  border-radius: 5px;
  align-content: center;
}

.explanation-1-shape-1 {
  width: 120px;
  height: 75px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.explanation-1-shape-1-a {
  width: 15px;
  height: 15px;
  margin: 0px 4px 5px 1px;
  border-radius: 5px;
  border: 1px solid #141a1f;
  box-sizing: border-box;
}

.explanation-1-shape-2 {
  width: 45px;
  margin-left: 10px;
  height: 75px;
}

.explanation-1-shape-2-a {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-sizing: border-box;
  background-color: #141a1f;
  box-shadow: inset 0 1px 0 #3a4854, 0 5px 0 #0e1215;
  padding: 10px 10%;
}

.explanation-1-shape-2-a-a {
  width: 100%;
  height: 2px;
  background-color: #ffc048;
  margin-bottom: 5px;
  display: block;
}

.explanation-2-box {
  width: 200px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border: 2px solid #485460;
  border-radius: 5px;
  align-content: center;
}

.explanation-2-shape-1 {
  width: 120px;
  height: 75px;
  display: flex;
  flex-wrap: wrap;
}

.explanation-2-shape-1-a {
  flex: 0 0 50%;
  height: 37.5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.explanation-2-shape-1-a-a {
  flex: 100%;
  background-color: #485460;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2.5px;
  border-radius: 5px;
}

.explanation-2-shape-1-a-a-active {
  box-sizing: border-box;
  border: 2px solid #05c46b;
}

.explanation-2-shape-2 {
  width: 45px;
  height: 70px;
  margin: 3px 2px;
  background-color: #d2dae2;
  border-radius: 5px;
}

.page-home-explanation {
  text-align: center;
  margin: 10px 0px;
  font-size: 0.9em;
  font-weight: 600;
}

.emphasis {
  font-weight: bold;
  color: #d2dae2;
}

.page-home-explanation-divider {
  border-bottom: 1px solid #d2dae2;
  width: 50%;
  margin: 10px 0px 20px 25%;
}

.page-home-explanation-parody {
  font-size: 0.7em;
  text-align: center;
  color: #808e9b;
}

.page-home-explanation-parody a {
  color: #575fcf;
  text-decoration: underline;
}

.page-home-explanation-parody a:hover {
  color: #ef5777;
  text-decoration: underline;
}

.page-home-join-desc-divider {
  border-bottom: 1px solid #485460;
  width: 50%;
  margin: 20px 0px 20px 0px;
}

.page-home-join-user-info {
  color: #808e9b;
  width: 100%;
  text-align: center;
  margin-bottom: 20px;
}

.page-home-join-username {
  font-weight: bold;
  color: #0be881;
}

.page-home-join-user-logout-btn {
  border-radius: 5px;
  border: 1px solid #ffc048;
  text-align: center;
  cursor: pointer;
  background-color: #141a1f;
  color: #ffc048;
  width: 100%;
  padding: 10px;
  font-size: 1em;
  transition: 300ms ease-in-out all;
}

.page-home-join-user-logout-btn:hover {
  border: 1px solid #ffc048;
  background-color: #ffc048;
  color: #141a1f;
}

.page-home-sign-in-google {
  background-image: url("btn_google_signin_light_normal_web.png");
  width: 191px;
  height: 46px;
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
}

.page-home-sign-in-google:active {
  background-image: url("btn_google_signin_light_pressed_web.png");
}

.page-home-sign-in-guest {
  border-radius: 5px;
  border: 1px solid #ffc048;
  text-align: center;
  cursor: pointer;
  background-color: #141a1f;
  color: #ffc048;
  font-size: 1em;
  transition: 300ms ease-in-out all;
  width: 186px;
  height: 44px;
  box-sizing: border-box;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page-home-sign-in-guest:hover {
  border: 1px solid #ffc048;
  background-color: #ffc048;
  color: #141a1f;
}

.page-home-sign-in-options {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.page-home-sign-in-or {
  font-size: 20px;
  color: #485460;
  margin: 10px 0px;
  width: 100%;
  text-align: center;
}

.page-home-sign-in-options-desc {
  font-weight: bold;
  text-align: center;
  color: #ffc048;
  margin-bottom: 20px;
}

.page-home-sign-in-privacy-note {
  margin-top: 20px;
  text-align: center;
  font-size: 0.8em;
  color: #485460;
}
