.room-info, .account-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.room-info-display, .account-info-display {
  font-size: 1.25em;
  font-family: 'Seven Segment', sans-serif;
  color: #141a1f;
  display: inline-block;
  padding: 2.5px 5px 2.5px 5px;
  border-radius: 5px;
  width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
  background-color: #ffc048;
}
