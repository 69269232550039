/*.sound-button {
  cursor: pointer;
  font-size: 2em;
  padding: 10px;
  margin: 10px;
  flex: 1;
  flex-basis: 25%;
  text-align: center;
  border-radius: 5px;
  width: 25%;
  background-color: black;
}
*/
.sound-button {
  position: relative;
  display: inline-block;
  margin: 10px;
}

.sound-button div {
  color: white;
  font-weight: bold;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  display: block;
  position: relative;
  padding: 10px 20px;
  width: 60px;

/*  text-shadow: 0px 1px 0px #000;*/
/*  filter: dropshadow(color=#000, offx=0px, offy=1px);*/

  border-radius: 5px;

  cursor: pointer;
}

.sound-button div:active {
  top: 10px !important;
  background-color: #ffc048 !important;
  box-shadow: inset 0 1px 0 #ffa801, inset 0 -3px 0 #1e272e !important;
}

.sound-button:after {
  content: "";
  height: 100%;
  width: 100%;
  padding: 4px;
  position: absolute;
  bottom: -15px;
  left: -4px;
  z-index: -1;
  background-color: #141a1f;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .sound-button div {
    width: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .sound-button div {
    width: 20px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .sound-button div {
    width: 20px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .sound-button div {
    width: 20px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .sound-button div {
    width: 20px;
  }
}
