.sound-button-small {
  background-color: #141a1f;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
}

.sound-button-small:active {
  opacity: 0.5;
}
