.active-users {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.active-users-display {
  font-size: 1.25em;
  font-family: 'Seven Segment', sans-serif;
  color: #ffc048;
  display: inline-block;
  padding: 2.5px 5px 2.5px 5px;
  border-radius: 5px;
  width: 100%;
  background-color: #1e272e;
  text-overflow: ellipsis;
  overflow: hidden;
}

.display-muted {
  color: #485460;
}

.active-users-header {
  font-size: 15px;
  color: #ffc048;
  padding: 5px 0px;
}
